//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
$white: #fff !default;
$gray-50: #f6f6f6 !default;
$gray-100: #babfc7 !default; // $gray-lightest
$gray-200: #ededed !default; // $gray-lighter
$gray-300: #dae1e7 !default; // $gray-light
$gray-400: #636363 !default;
$gray-500: #adb5bd !default;
$gray-600: #b8c2cc !default; // $gray
$gray-700: #4e5154 !default;
$gray-800: #1e1e1e !default; // $gray-dark
$gray-900: #2a2e30 !default;
$black: #22292f !default; // 231f48 22292f

$blue: #062A93 !default; //$primary
$green: #26bf69 !default; //$success
$light-blue: #67A6F8 !default; //$info
$orange: #df8430 !default; //$warning
$red: #dd174f !default; //$danger

$primary: $blue !default;
$success: $green;
$danger: $red;
$secondary: $light-blue !default;
$info: $light-blue !default;
$warning: $orange !default;
$dark: #1b2342 !default; // Changing primary color
